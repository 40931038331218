import request from '../utils/request'
const addApi = {
  customerAdd(data:Object) {
    return request({
      url: '/moonlit/qrcode/customer/add', // 查询客户类别下级所有树 0代表全部
      method: 'POST',
      headers:{
        isLoading:"true"
      },
      data
    })
  },
  smartTitle(data:Object) {
    return request({
      url: '/moonlit/rapid/invoice/smart-invoice-title-ignore', // 查询客户类别下级所有树 0代表全部
      method: 'POST',
      data
    })
  },
}
export default addApi