import axios from 'axios';
import { Toast } from "antd-mobile"
Toast.config({ duration: 1000, position: 'center' })
// create an axios instance
const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use((config:any) => {
    // do something before request is sent
    // if (getToken()) {
    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation
    //   config.headers['Access-Token'] = getToken()
    //   if (store.getters.userInfo && store.getters.userInfo.enterpriseId) {
    //     config.headers['Enterprise-Id'] = 1
    //   }
    // }
    if(!config.headers) {
      config.headers = {}
    }
    if (config.headers&&config.headers['isLoading'] !== false) {
      showLoading()
    }

    return config
  },(error:any) => {
    if (error.config&&error.config.headers&&error.config.headers['isLoading'] !== 'false') {
      hideLoading()
    }
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

function showLoading() {
  Toast.show(
    {
      content:"加载中...",
      icon:"loading",
      duration: 0,
      maskClickable:false
    }
  )
}
function hideLoading() {
  Toast.clear()
}
// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response:any) => {
    const res = response.data

    if (response.config.headers.isLoading !== false) {
      hideLoading()
    }

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== '200') {
      Toast.show({
        content: res.message || 'Error',
        icon: 'fail',
        duration: 1 * 1000
      })
      

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === '401') {
      
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  (error:any) => {
    console.log('err' + error) // for debug
    Toast.show({
      content: error.message,
      icon: 'fail',
      duration: 1 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
