/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
 export function isExternal(path:string): boolean {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str:string):boolean {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}
// 校验手机号
export function validatePhone(value:string) {
  return /^1[3456789]\d{9}/.test(value)
}
// 校验固话
export function validateTel(value:string) {
  return /^0\d{2,3}-?\d{7,8}$/.test(value)
}
// 邮箱
export function validateEmail(str:string) {
  return /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(str)
}
// 验证税号 支持15位、17位、18位、20位
export function validateEin(str:string) {
  return /^(([0-9A-Z]{15})|([0-9A-Z]{17})|([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10})|([0-9A-Z]{20}))$/.test(str)
}
// 只能输入数字
export function validNumber(value:string) {
  return /^[0-9]*$/.test(value)
}

// 只能文字和 英文
export function validText(value:string) {
  return /^[\u4E00-\u9FA5A-Za-z0-9]+/.test(value)
}
