import React, { useState, Fragment ,useEffect} from 'react';
import logo from './logo.svg';
import './App.scss';
import { validatePhone, validateEmail, validateEin ,validText} from "./utils/validate"
import { useRequest } from 'ahooks'
import {
  Form,
  Input,
  Button,
  Toast
} from 'antd-mobile'
import addApi from "./api/add"

interface ModelValue {
  cusIdentityFlag: Number
  email: string,
  name: string,
  mobile:string,
  purchaserAddress: string,
  purchaserBankAccount: string,
  purchaserBankName: string,
  purchaserTel: string,
  taxCode: string,
  enterpriseName:string,
  enterpriseTaxNo:string
}

function App() {

  const [formRef] = Form.useForm()
  //enterprise 企业 personal
  const [type, setType] = useState<string>('enterprise')
  const [email, setEmail] = useState<string>("")
  const [name, setName] = useState<string>("")
  const [mobile,setMobile] = useState<string>("")
  const [purchaserAddress, setPurchaserAddress] = useState<string>("")
  const [purchaserBankName, setPurchaserBankName] = useState<string>("")
  const [taxCode, setTaxCode] = useState<string>("")
  const [purchaserTel, setPurchaserTel] = useState<string>("")

  const [maskList, setMaskList] = useState<Array<ModelValue>>([])

  const [showMask,setMask] = useState<Boolean>(false)
  // 点击其他区域关闭
  document.addEventListener('click', e => {
    setMask(false)
  })

  function getQueryVariable(variable:string) {
    const urlList = window.location.href.split('?')
    var query = urlList[1]
    if (query) {
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) { return pair[1] }
      }
    }
    return (false)
  }


  function changeEnterpriseType() {
    setType('enterprise')
  }
  function changePersonalType() {
    setType('personal')
  }
  function selectItem(e:any) {
    e.nativeEvent.stopImmediatePropagation();
  }
  const checkMobile = (_: any, value: string) => {
    if (mobile) {
      if (!validatePhone(mobile)) {
        return Promise.reject(new Error('请输入正确手机号!'))
      }

    }
    return Promise.resolve()
  }

  const checkEmail = (_: any, value: string) => {
    if (email) {
      if (!validateEmail(email)) {
        return Promise.reject(new Error('请输入正确邮箱!'))
      }

    }
    return Promise.resolve()
  }

  const checkNo = (_: any, value: string) => {
    if (taxCode) {
      if (!validateEin(taxCode)) {
        return Promise.reject(new Error('请输入正确的纳税人识别号!'))
      }else {
        return Promise.resolve()
      }
    } else {
      return Promise.reject(new Error('请输入纳税人识别号!'))
    }
  }

  const checkName =  (_: any, value: string) => {
    if (name) {
      if (getInputLength(name) > 80) {
        return Promise.reject(new Error('发票抬头不能超过80个字符!'))
      }else {

        if(!validText(name)){
          return  Promise.reject(new Error('发票抬头不能输入特殊字符!'))
        }
        return Promise.resolve()
      }
    } else {
      return Promise.reject(new Error('请输入发票抬头!'))
    }
  }

  const checkAddress = (_: any, value: string) => {
    if (purchaserAddress) {
      if (getInputLength(purchaserAddress) > 100) {
        return Promise.reject(new Error('地址、电话不能超过100个字符!'))
      }
      return Promise.resolve()
   
    } else {
      return Promise.resolve()
    }
  }

  const checkBankName  = (_: any, value: string) => {
    if (purchaserBankName) {
      if (getInputLength(purchaserBankName) > 100) {
        return Promise.reject(new Error('开户行及账号不能超过100个字符!'))
      }
      return Promise.resolve()
   
    } else {
      return Promise.resolve()
    }
  }

  const onFinish = () => {
    let data = {
      cusIdentityFlag:type === 'enterprise'?0:1,
      email:email,
      name:name,
      purchaserAddress:type === 'enterprise'?purchaserAddress:null,
      purchaserBankName:type === 'enterprise'?purchaserBankName:null,
      taxCode:type === 'enterprise'?taxCode:null,
      mobile:mobile,
      purchaserTel:purchaserTel,
      enterpriseId: getQueryVariable('id')
    }
    addApi.customerAdd(data).then((res:Object)=>{
      Toast.show({
          content:"提交成功!",
          icon:"success",
      })
      setEmail("")
      setName("")
      setPurchaserAddress("")
      setPurchaserBankName("")
      setTaxCode("")
      setPurchaserTel("")
      setMobile("")
      formRef.resetFields()
      
    })
    
  
  }

  function getNameList(val:string){
    let data = {
      taxName:val
    }
    return  new Promise((resolve)=>{
      addApi.smartTitle(data).then((res:any)=>{
        let  list = res.data
        setMaskList(list)
        if(list&&list.length > 0)
        setMask(true)
        resolve(res)
       })
    })

  }

  function  getInputLength(val:string) {
    // 当前字符串的字节数
    let strlen = 0
    // 限制的最大输入长度
    for (var i = 0; i < val.length; i++) {
      // 判别是中文字符还是英文字符的方法
      if (val.charCodeAt(i) < 27 || val.charCodeAt(i) > 126) { // 中文和中文字符
        strlen += 2
      } else {
        strlen++
      }
    }
    return strlen
  }

  function selectSubItem(data:ModelValue) {
   formRef.setFieldsValue({
    name:data.enterpriseName,
    purchaserAddress:data.purchaserAddress+data.purchaserTel,
    purchaserBankName:data.purchaserBankName + data.purchaserBankAccount,
    taxCode:data.enterpriseTaxNo,
    purchaserTel:data.purchaserTel
   })
    setName(data.enterpriseName)
    setPurchaserAddress(data.purchaserAddress+data.purchaserTel)
    setPurchaserBankName(data.purchaserBankName + data.purchaserBankAccount)
    setTaxCode(data.enterpriseTaxNo)
    setMask(false)
  }

  const { data, run } = useRequest( getNameList, {
    debounceWait: 600,
    manual: true
  });


  return (
    <div className="App">
      <div className='head'>
        <div className='head-item head-margin-right' onClick={changeEnterpriseType}>
          <div className='head-left-title'> {"企业"}</div>
          {type === 'enterprise' ? <div className='triangle' /> : undefined}
        </div>
        <div className='head-item' onClick={changePersonalType}>
          <div className='head-left-title'> {"个人"}</div>
          {type === 'personal' ? <div className='triangle' /> : undefined}
        </div>

      </div>
      <div className='app-title'>
        {"发票抬头"}
      </div>
      <Form 
       form={formRef}
       initialValues={{
          name: name,
          purchaserAddress: purchaserAddress,
          purchaserBankName:purchaserBankName,
          taxCode:taxCode,
          purchaserTel:purchaserTel,
          email:email,
          mobile:mobile
        }}
        onFinish={onFinish}
        footer={
          <Button className='foot-btn' color='primary'  block type='submit'>
          提交
        </Button>
        }
        >
        <Form.Item
          label="发票抬头"
          name='name'
          style={{position:"relative"}}
          rules={
          [{ required: true,message:"发票抬头不能为空" },{ validateTrigger:['onChange']},{ validator: checkName }]
          }>
          <div>
            <Input placeholder='请输入发票抬头'  value={name} onChange={val => {
              setName(val)
              if(getInputLength(val) > 7 && type === 'enterprise' ) {
                run(val)
              }
              
            }}  clearable />
            {
              showMask? <div className='mask-list' onClick={selectItem}>
              {
                maskList.map((item,key)=>{
                  return (
                    <div key={key+'mask'} className='mask-list-item' onClick={()=>selectSubItem(item)}>
                      {item.enterpriseName}
                  </div>
                  )
                })
              }
            </div>:undefined
            }
     
          </div>

        </Form.Item>
        {
          type === 'enterprise' ? (
            <Fragment>
              <Form.Item
                label="纳税人识别号"
                name='taxCode'
                rules={
                  [{ required: true,message:"纳税人识别号必填" }, { validator: checkNo }]
                 }>
                <Input value={taxCode} onChange={val => {
                  setTaxCode(val)
                }} placeholder='请输入纳税人识别号' clearable />

              </Form.Item>

              <Form.Item
                label="地址、电话"
                name='purchaserAddress'
                rules={
                  [{ required: false }, { validator: checkAddress }]
                 }
              >
                <Input value={purchaserAddress} placeholder='请输入地址、电话' onChange={val => {
                  setPurchaserAddress(val)
                }} clearable />
              </Form.Item>

              <Form.Item
                label="开户行及账号"
                name='purchaserBankName'
                rules={
                  [{ required: false }, { validator: checkBankName }]
                 }
              >
                <Input value={purchaserBankName} placeholder='请输入开户行及账号' onChange={val => {
                  setPurchaserBankName(val)
                }} clearable />
              </Form.Item>
            </Fragment>

          ) : undefined
        }


        <div className='app-title'>
          {"收票人信息(电子发票填写)"}
        </div>

        <Form.Item
          label="收票人手机号"
          name='mobile'
          rules={[{ validator: checkMobile }]}
        >
          <Input value={mobile} placeholder='请输入收票人手机号' clearable onChange={val => {
            setMobile(val)
          }} />
        </Form.Item>

        <Form.Item
          label="收票人邮箱"
          name='email'
          rules={[{ validator: checkEmail }]}
        >
          <Input value={email} placeholder='请输入收票人邮箱' clearable onChange={val => {
            setEmail(val)
          }} />
        </Form.Item>

      </Form>


    </div>
  );
}


export default App;
